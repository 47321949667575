import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Jsona } from 'jsona';
import { TJsonApiBody } from 'jsona/lib/JsonaTypes';

import { map, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { InyovaConfigService } from './inyova-config.service';
import { FeatureFlag } from '../../../models';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService implements OnDestroy {
  protected readonly onDestroy$ = new Subject<void>();

  private jsona = new Jsona();
  private _featureFlags: Map<string, boolean> = new Map();
  private _initialized = false;

  private urlV3: string;

  constructor(
    private http: HttpClient,
    private sharedService: InyovaConfigService
  ) {
    this.sharedService
      .getApiData()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(({ urlV3 }) => {
        this.urlV3 = urlV3;
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initialize() {
    return this.getFeatureFlags().subscribe((featureFlags) => {
      this._featureFlags = new Map(featureFlags.map(({ feature_key, value }) => [feature_key, value]));
      this._initialized = true;
    });
  }

  getFeatureFlags(): Observable<FeatureFlag[]> {
    return this.http
      .get<TJsonApiBody>(`${this.urlV3}/flipper_gates`)
      .pipe(map((response) => this.jsona.deserialize({ ...response }) as FeatureFlag[]));
  }

  get initialized() {
    return this._initialized;
  }

  featureDisabled(featureName: string) {
    return !this.featureEnabled(featureName);
  }

  featureEnabled(featureName: string) {
    if (!featureName) {
      return true;
    }
    return this._featureFlags && !!this._featureFlags.get(featureName);
  }
}
