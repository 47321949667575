export const environment = {
  name: 'staging',
  production: false,
  productType: 'mobile-app',
  token_auth_config: {
    apiBase: 'https://api.staging.europe.inyova.cloud'
  },
  apiUrl: 'https://api.staging.europe.inyova.cloud/api/v2/customers',
  apiUrlV3: 'https://api.staging.europe.inyova.cloud/api/v3',
  amplitudeApiKey: '3a9b51c86d614e44369f3e2b8e78e026',
  customersApp: {
    ch: 'https://staging.inyova.ch',
    de: 'https://staging.inyova.de',
    at: 'https://staging.inyova.at'
  }
};
